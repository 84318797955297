import { Box, Typography } from "@mui/material";
import React from "react";
import ProviderContactList from "../ProviderContactList";
import BankAccountList from "../BankAccountList";
import ActivityTable from "../ActivityTable";

export default function ProviderDetail() {
  return (
    <Box>
      <Typography variant="h4">Detalles generales</Typography>
      <Typography variant="h4">Contacto</Typography>
      <ProviderContactList/>
      <Typography variant="h4">Información Bancaria</Typography>
      <BankAccountList />
      <Typography variant="h4">Información Fiscal</Typography>



    </Box>
  );
}
