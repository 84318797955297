import React, { createContext, useContext, useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackContext = createContext();

export const useSnack = () => useContext(SnackContext);

const SnackProvider = ({ children }) => {
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const openSnack = useCallback((message, severity = "info") => {
    setSnack({ open: true, message, severity });
  }, []);

  const closeSnack = () => {
    setSnack((prev) => ({ ...prev, open: false }));
  };

  return (
    <SnackContext.Provider value={{ openSnack }}>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={closeSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnack}
          severity={snack.severity}
          sx={{
            width: "100%",
            fontSize: "1.2rem", // Adjust the font size
            padding: "20px", // Adjust the padding
          }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackContext.Provider>
  );
};

export default SnackProvider;
