import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import CrudProviderTypes from "../../organisms/Catalogues/CrudProviderTypes";
import CrudProviderSubtypes from "../../organisms/Catalogues/CrudProviderSubtypes";
import CrudProviderCorporationType from "../../organisms/Catalogues/CrudProviderCorporationType";
import CrudProviderServiceType from "../../organisms/Catalogues/CrudProviderServiceType";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import CrudProviderTaxRegime from "../../organisms/Catalogues/CrudProviderTaxRegime";
import CrudProviderSubregime from "../../organisms/Catalogues/CrudProviderSubregime";
import CrudEconomicActivity from "../../organisms/Accounting/CrudEconomicActivity";
import CrudExpenseAccounts from "../../organisms/Accounting/CrudExpenseAccounts";

import CrudImportForeignTransport from "../../organisms/Catalogues/CrudImportForeignTransport";
import CrudImportForwarder from "../../organisms/Catalogues/CrudImportForwarder";
import CrudImportCustomsAgent from "../../organisms/Catalogues/CrudImportCustomsAgent";
import CrudImportShipmentType from "../../organisms/Catalogues/CrudImportShipmentType";
import CrudImportShipmentMode from "../../organisms/Catalogues/CrudImportShipmentMode";
import CrudCurrencies from "../../organisms/Catalogues/CrudCurrencies";

export default function CataloguesScreen() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} sx={{}}>
          <Tab label="Catálogos Genéricos" />
          <Tab label="Catálogos Fiscales" />
          <Tab label="Catálogos Contables" />
          <Tab label="Catálogos de importación" />
          <Tab label="Tipo de cambio" />
        </Tabs>
      </Box>

      <LGTabPanel value={value} index={0}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Tipos de proveedor</Typography>
          <CrudProviderTypes />
          <Typography variant="h4">Origen del proveedor</Typography>
          <CrudProviderSubtypes />
        </Stack>
      </LGTabPanel>

      <LGTabPanel value={value} index={1}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Tipos de sociedad moral</Typography>
          <CrudProviderCorporationType />
          <Typography variant="h4">Regímenes Fiscales</Typography>
          <CrudProviderTaxRegime />
          <Typography variant="h4">Subregímenes</Typography>
          <CrudProviderSubregime />
        </Stack>
      </LGTabPanel>

      <LGTabPanel value={value} index={2}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Tipo de servicio o producto</Typography>
          <CrudProviderServiceType />
          <Typography variant="h4">Actividades Económicas</Typography>
          <CrudEconomicActivity />
          <Typography variant="h4">Cuentas contables</Typography>
          <CrudExpenseAccounts />
        </Stack>
      </LGTabPanel>

      <LGTabPanel value={value} index={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Navieras / Aerolineas</Typography>
              <CrudImportForeignTransport />
              <Typography variant="h4">Forwarders</Typography>
              <CrudImportForwarder />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Agentes Aduanales</Typography>
              <CrudImportCustomsAgent />
              <Typography variant="h4">Tipos de Embarque</Typography>
              <CrudImportShipmentType />
              <Typography variant="h4">Modos de Embarque</Typography>
              <CrudImportShipmentMode />
            </Stack>
          </Grid>
        </Grid>
      </LGTabPanel>

      <LGTabPanel value={value} index={4}>
        <Stack direction={"column"} spacing={2} mt={5}>
          <Typography variant="h4">Tipos de cambio</Typography>
          <CrudCurrencies />
        </Stack>
      </LGTabPanel>
    </DashboardLayout>
  );
}
