import React, { useEffect, useState } from "react";
import BankAccountItem from "../../../molecules/Providers/BankAccountItem";
import { Button, Grid, Icon, Stack, Typography } from "@mui/material";
import LGModal from "../../../molecules/LGModal";
import AddBankDetailForm from "../../../molecules/Providers/AddBankDetailForm";
import {
  getProviderAccountDetail,
  getProviderBankDetailList,
} from "../../../api/providers";

export default function BankAccountList({ props }) {
  const { id } = props;
  const [openModal, setOpenModal] = useState(false);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [providerId, setProviderId] = useState("");

  const handleModalClose = () => {
    setOpenModal(false);
    fetchData();
  };

  const fetchProvider = async () => {
    try {
      const accountDetail = await getProviderAccountDetail(id);
      setProviderId(accountDetail.data.provider.id);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    const res = await getProviderBankDetailList(providerId);
    if (res.data) {
      setBankAccountList(res.data);
    }
  };

  useEffect(() => {
    fetchProvider();
  }, []);

  useEffect(() => {
    if (providerId) {
      fetchData();
    }
  }, [providerId]);

  return (
    <>
      <LGModal
        open={openModal}
        header="Agregar Contacto"
        handleClose={handleModalClose}
      >
        <AddBankDetailForm props={{ providerId, handleModalClose }} />
      </LGModal>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h4">Información Bancaria</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<Icon>add</Icon>}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      {bankAccountList.map((e, index) => (
        <BankAccountItem key={index} props={e} />
      ))}
    </>
  );
}
