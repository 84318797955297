import { Box, Grid } from "@mui/material";

const ENV = process.env.REACT_APP_ENV;

export const DrawerHeader = ({ children }) => (
  <Grid
    container
    direction={"row"}
    alignContent={"center"}
    justifyContent={"flex-end"}
    height={"64px"}
    py={4}
    sx={{
      backgroundColor:
        ENV == "LOCAL" ? "#582A72" : ENV == "QA" ? "#297B48" : "#1A73E8",
    }}
  >
    <Grid item>{children}</Grid>
  </Grid>
);
