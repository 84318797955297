import React, { useEffect, useState } from "react";
import LGCrud from "../../LGCrud";
import {
  addProviderActivity,
  getProviderAccountDetail,
  getProviderActivityList,
} from "../../../api/providers";
import LGSelectCellRenderer from "../../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../../LGCrud/LGSelectCellEditor";
import { getExpenseAccountList } from "../../../api/accounting";

export default function ActivityTable({ props }) {
  const { id } = props;
  const [activities, setActivities] = useState([]);
  const [expenseAccounts, setExpenseAccounts] = useState([]);

  const fetchExpenseAccounts = async () => {
    try {
      const res = await getExpenseAccountList();
      setExpenseAccounts(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  // GET PROVIDER ID BY ACCOUNT ID
  const [providerId, setProviderId] = useState("");
  const fetchProvider = async () => {
    try {
      const accountDetail = await getProviderAccountDetail(id);
      setProviderId(accountDetail.data.provider.id);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchExpenseAccounts();
    fetchProvider();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getProviderActivityList(providerId);
      setActivities(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.expenseAccountId = payload.expenseAccount.id;
    payload.providerId = providerId;
    console.log(payload);
    const res = await addProviderActivity(payload);
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    // const res = await updateTaxRegime(id, payload);
  };

  const handleDelete = async (id) => {
    // const res = await deleteTaxRegime(id);
  };

  useEffect(() => {
    if (providerId) {
      fetchData();
    }
  }, [providerId]);

  return (
    <LGCrud
      data={activities}
      columns={[
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
        {
          field: "expenseAccount",
          headerName: "Actividad económica",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) =>
                `${v.name}`
              }
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={expenseAccounts}
              labelTemplate={(v) =>
                `${v.name} | ${v.economicActivity.productServiceType.code}-${v.economicActivity.contpaqiPrefixCode}-${v.contpaqiSuffixCode}`
              }
            />
          ),
        },
        {
          field: "percentage",
          headerName: "Porcentaje",
          flex: 0.5,
          editable: true,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
