import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  Container,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { useSnack } from "../../services/SnakbarProvider";
import { getCatalogue } from "../../api/common/catalogues";
import { handleError } from "../../services/errorHandler";
import { addProduct } from "../../api/products";

const validationSchema = Yup.object({
  name: Yup.string().required("El nombre es obligatorio"),
  key: Yup.string().required("Key is required"),
  code: Yup.string().required("Code is required"),
  brand: Yup.string().required("La marca es obligatoria"),
  productTypeId: Yup.number().required("Tipo de producto es obligatorio"),
  unitId: Yup.number().required("La unidad es obligatoria"),
  quantity: Yup.number()
    .required("La cantidad es obligatoria")
    .min(1, "La cantidad no puede ser menor a 1"),
  propsKeys: Yup.array().of(Yup.string()),
  propsValues: Yup.array().of(Yup.string()),
});

const AddFormComponent = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const { openSnack } = useSnack();

  useEffect(() => {
    // Fetch product types and units
    const fetchDropdownData = async () => {
      try {
        const productTypeResponse = await getCatalogue("PRODUCT_TYPE");
        const unitResponse = await getCatalogue("UNIT");
        setProductTypes(productTypeResponse.data);
        setUnits(unitResponse.data);
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    };
    fetchDropdownData();
  }, []);

  const useAutoGenerateCode = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      const generateCode = () => {
        const _1 = values.productTypeId
          ? productTypes
              .find((e) => e.id == values.productTypeId)
              .name.substring(0, 3)
              .toUpperCase()
          : "";
        const _2 = values.propsValues.reduce(
          (acc, curr) => acc.concat(curr.substring(0, 2).toUpperCase()),
          ""
        );
        const unitCode = units.find((e) => e.id == values.unitId);
        const _3 = `${values.quantity}${
          unitCode ? unitCode.code.toUpperCase() : ""
        }`;
        const _4 = values.brand
          ? values.brand.substring(0, 10).toUpperCase()
          : "";
        const a = values.name.split(" ");
        const _5 = (a[a.length - 1] || values.name).toUpperCase();

        return `${_1}-${_2}-${_3}-${_4}-${_5}`;
      };

      setFieldValue("code", generateCode());
    }, [
      values.name,
      values.key,
      values.brand,
      values.productTypeId,
      values.unitId,
      values.quantity,
      values.propsValues,
      setFieldValue,
    ]);
  };

  // Method to handle form data
  const handleSubmit = async (values) => {
    const props = values.propsKeys.reduce((acc, key, index) => {
      if (key) acc[key] = values.propsValues[index];
      return acc;
    }, {});
    const formValues = { ...values, props: JSON.stringify(props) };
    delete formValues.propsKeys;
    delete formValues.propsValues;
    try {
      const res = await addProduct(formValues);
      openSnack("Producto agregado con éxito", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const FormFields = () => {
    useAutoGenerateCode(); // Call the hook here
    const { errors, touched } = useFormikContext();

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Field
            as={TextField}
            name="name"
            label="Nombre"
            fullWidth
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            as={TextField}
            name="brand"
            label="Marca"
            fullWidth
            error={touched.brand && !!errors.brand}
            helperText={touched.brand && errors.brand}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            as={TextField}
            name="key"
            label="Clave"
            fullWidth
            error={touched.key && !!errors.key}
            helperText={touched.key && errors.key}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            as={TextField}
            name="code"
            label="Código"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            error={touched.code && !!errors.code}
            helperText={touched.code && errors.code}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            as={TextField}
            select
            name="productTypeId"
            label="Tipo de producto"
            fullWidth
            error={touched.productTypeId && !!errors.productTypeId}
            helperText={touched.productTypeId && errors.productTypeId}
          >
            {productTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            as={TextField}
            select
            name="unitId"
            label="Unidad"
            fullWidth
            error={touched.unitId && !!errors.unitId}
            helperText={touched.unitId && errors.unitId}
          >
            {units.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.name}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            as={TextField}
            name="quantity"
            label="Cantidad"
            type="number"
            fullWidth
            error={touched.quantity && !!errors.quantity}
            helperText={touched.quantity && errors.quantity}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Propiedades</Typography>
        </Grid>
        {[0, 1, 2, 3, 4].map((index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name={`propsKeys[${index}]`}
                label={`Propiedad ${index + 1}`}
                fullWidth
                error={
                  touched.propsKeys?.[index] && !!errors.propsKeys?.[index]
                }
                helperText={
                  touched.propsKeys?.[index] && errors.propsKeys?.[index]
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name={`propsValues[${index}]`}
                label={`Valor ${index + 1}`}
                fullWidth
                error={
                  touched.propsValues?.[index] && !!errors.propsValues?.[index]
                }
                helperText={
                  touched.propsValues?.[index] && errors.propsValues?.[index]
                }
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
          key: "",
          code: "",
          brand: "",
          productTypeId: "",
          unitId: "",
          quantity: "",
          propsKeys: ["", "", "", "", ""],
          propsValues: ["", "", "", "", ""],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <FormFields />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddFormComponent;
