import { Box, Typography } from "@mui/material";

export function LGTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={{ display: value === index ? "block" : "none" }} // Only show when the tab is active
    >
      {value === index && <Box sx={{ py: 8, px: 2 }}>{children}</Box>}
    </Box>
  );
}
