import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { json, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { getRoles, register } from "../../../api/auth";
import { getPlantList } from "../../../api/enterprise";
import { handleError } from "../../../services/errorHandler";
import { useSnack } from "../../../services/SnakbarProvider";

function RegisterForm() {
  const { openSnack } = useSnack();
  const [roles, setRoles] = useState([]);
  const [plants, setPlants] = useState([]);

  const navigate = useNavigate();
  const validationSchema = Yup.object({
    userName: Yup.string()
      .trim()
      .required("El usuario es requerido")
      .matches(/^\S*$/, "El usuario no puede tener espacios"),
    email: Yup.string("Ingresa un email válido")
      .email("Ingresa un email válido")
      .required("El email es requerido"),
    password: Yup.string("Ingresa una contraseña")
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("La contraseña es requerida")
      .test(
        "isValidPass",
        "Necesita al menos una mayuscula, una minúscula y un número",
        (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 3;
          const conditions = [hasUpperCase, hasLowerCase, hasNumber];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }
      ),
    roleId: Yup.string("Elige un rol"),
    plantId: Yup.number("Elige una planta"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await register({
          ...values,
        });
        openSnack("Usuario registrado correctamente", "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    },
  });

  const fetchData = async () => {
    const roles = await getRoles();
    setRoles(roles.data);

    try {
      const plants = await getPlantList();
      setPlants(plants.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Typography component="h1" variant="h5">
        Registrar nuevo usuario
      </Typography>
      <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <TextField
          required
          fullWidth
          margin="normal"
          id="userName"
          label="Usuario"
          name="userName"
          autoComplete="userName"
          value={formik.values.userName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          autoComplete="current-password"
        />
        <Grid container my={2} columnSpacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="planta-dropdown-id">Planta</InputLabel>
              <Select
                name="plantId"
                labelId="planta-dropdown-id"
                id="demo-simple-select"
                value={formik.values.plantId}
                label="Planta"
                onChange={formik.handleChange}
              >
                {plants.map((e) => (
                  <MenuItem value={e.id} key={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="rol-dropdown-id">Rol</InputLabel>
              <Select
                name="roleId"
                labelId="rol-dropdown-id"
                id="demo-simple-select"
                value={formik.values.roleId}
                label="Rol"
                onChange={formik.handleChange}
              >
                {roles.map((e) => (
                  <MenuItem value={e.id} key={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Registrar usuario
        </Button>
      </Box>
    </>
  );
}

export default RegisterForm;
