import { api } from "..";
import { handleError } from "../../services/errorHandler";

const account_uri = "/api/account";
const roles_uri = "/api/roles";

export const login = (email, password) => {
  return api.post(`${account_uri}/login`, { email, password });
};

export const register = (data) => {
  var res = api.post(`${account_uri}/register`, { ...data });
  return res;
};

export const logout = () => {
  localStorage.removeItem("accessToken");
};

export const getRoles = () => {
  return api.get(`${roles_uri}`);
};
