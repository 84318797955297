import React, { useState } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Autocomplete,
  Chip,
  Stack,
  Typography,
  Icon,
  Divider,
  Box,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getExpenseAccountList } from "../../api/accounting";
import { getProductList } from "../../api/products";

const PurchaseOrderDetails = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const [productOptions, setProductOptions] = useState([]);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchProductOptions = async (inputValue) => {
    try {
      const res = await getProductList({ code: inputValue.toUpperCase() });
      setProductOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchExpenseAccounts = async () => {
    try {
      const res = await getExpenseAccountList();
      setExpenseAccountOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <FieldArray name="details">
      {({ push, remove }) => (
        <Grid container spacing={3}>
          {values.details.map((_, index) => (
            <Grid container item spacing={2} key={index}>
              <Grid
                container
                item
                xs={11}
                spacing={2}
                alignItems={"flex-start"}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    id="productId"
                    name={`details[${index}].productId`}
                    options={productOptions}
                    getOptionLabel={(option) => option.code}
                    onInputChange={(event, value) => fetchProductOptions(value)}
                    onChange={(event, value) => {
                      setFieldValue(
                        `details[${index}].productId`,
                        value ? value.id : ""
                      );
                      // setFieldValue(
                      //   `details[${index}].product`,
                      //   value ? value : ""
                      // );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Producto"
                        error={
                          touched.details?.[index]?.productId &&
                          Boolean(errors.details?.[index]?.productId)
                        }
                        helperText={
                          touched.details?.[index]?.productId &&
                          errors.details?.[index]?.productId
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    id="activityId"
                    name={`details[${index}].activityId`}
                    options={expenseAccountOptions}
                    getOptionLabel={(option) => option.name}
                    onInputChange={(event, value) =>
                      fetchExpenseAccounts(value.id)
                    }
                    onChange={(event, value) => {
                      setFieldValue(
                        `details[${index}].activityId`,
                        value ? value : ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Actividad Económica"
                        error={
                          touched.details?.[index]?.activityId &&
                          Boolean(errors.details?.[index]?.activityId)
                        }
                        helperText={
                          touched.details?.[index]?.activityId &&
                          errors.details?.[index]?.activityId
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Field
                    name={`details[${index}].quantity`}
                    label="Cantidad"
                    as={TextField}
                    type="number"
                  />
                </Grid>

                <Grid item xs={2}>
                  <Stack direction={"column"}>
                    <Typography variant="body2">Unidad</Typography>
                    <Chip label={_.product ? _.product.unit.code : "-"} />
                  </Stack>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Field
                    name={`details[${index}].unitPrice`}
                    label="Precio Unitario"
                    as={TextField}
                    type="number"
                  />
                </Grid>

                <Grid item xs={2}>
                  <Stack direction={"column"}>
                    <Typography variant="body2">Impuesto</Typography>
                    <Chip
                      label={
                        _.activityId
                          ? (_.activityId.taxRate / 100).toLocaleString(
                              undefined,
                              { style: "percent", minimumFractionDigits: 2 }
                            )
                          : "-"
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton onClick={() => remove(index)} color="error">
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box height={"1px"} sx={{ backgroundColor: "#ccf" }}></Box>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              onClick={() => {
                push({
                  quantity: "",
                  unitPrice: "",
                  productId: "",
                  activityId: "",
                });
              }}
              color="primary"
              startIcon={<AddIcon />}
            >
              Agregar Artículo
            </Button>
          </Grid>
        </Grid>
      )}
    </FieldArray>
  );
};

export default PurchaseOrderDetails;
