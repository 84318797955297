import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, MenuItem, Button, Grid } from "@mui/material";
import {
  getCatalogue,
  getSubregimeList,
  getTaxRegimeList,
} from "../../../api/common/catalogues";
import { getCompanyList } from "../../../api/enterprise";
import NumericFormatInput from "../../../atoms/NumericFormatInput";
import { addProviderFromAccount } from "../../../api/providers";
import { handleError } from "../../../services/errorHandler";
import { useSnack } from "../../../services/SnakbarProvider";

// Validation Schema
const validationSchema = Yup.object().shape({
  legalName: Yup.string().required("El nombre legal es obligatorio"),
  code: Yup.string().required("El código es obligatorio"),
  taxId: Yup.string().required("El ID fiscal es obligatorio"),
  providerOriginId: Yup.number()
    .required("El Origen del proveedor es obligatorio")
    .integer(),
  providerTypeId: Yup.number().integer(),
  regimeId: Yup.number().integer(),
  subregimeId: Yup.number().integer(),
  corporationTypeId: Yup.number(),
  descripcion: Yup.string(),
  website: Yup.string().url("URL inválida"),
  creditDays: Yup.number()
    .required("Los días de crédito son obligatorios")
    .integer(),
});

// Initial Values
const initialValues = {
  legalName: "",
  code: "",
  taxId: "",
  providerOriginId: "",
  providerTypeId: "",
  regimeId: "",
  subregimeId: "",
  corporationTypeId: "",
  descripcion: "",
  website: "",
  creditDays: "",
  companyId: "",
  contpaqiAccountNo: "",
};

export default function AddProviderFromAccountForm({ onSubmit }) {
  const { openSnack } = useSnack();
  const [providerOrigins, setProviderOrigins] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const [regimes, setRegimes] = useState([]);
  const [subregimes, setSubregimes] = useState([]);
  const [corporationTypes, setCorporationTypes] = useState([]);
  const [companies, setCompanies] = useState([]);

  const checkIfMoralPerson = (providerTypeId) => {
    const typeCode = providerTypes.find((e) => e.id == providerTypeId);
    if (typeCode) {
      return typeCode.code == "PM";
    }
    return false;
  };

  const fetchData = async () => {
    try {
      const res = await getCatalogue();
      setProviderTypes(
        res.data.filter((e) => e.catalogueName === "PROVIDER_TYPE")
      );
      setProviderOrigins(
        res.data.filter((e) => e.catalogueName === "PROVIDER_ORIGIN")
      );
      setCorporationTypes(
        res.data.filter((e) => e.catalogueName === "CORPORATION_TYPE")
      );
      const regimeData = await getTaxRegimeList();
      setRegimes(regimeData.data);
      const subregimesData = await getSubregimeList();
      setSubregimes(subregimesData.data);
      const companiesData = await getCompanyList();
      setCompanies(companiesData.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleSubmit = async (values) => {
    try {
      const submitForm = await addProviderFromAccount(values);
      if (submitForm.status == 200) {
        openSnack("Proveedor guardado exitosamente", "success");
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  select
                  name="companyId"
                  label="Empresa"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.companyId && !!errors.companyId}
                  helperText={touched.companyId && errors.companyId}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="contpaqiAccountNo"
                  label="No. Contabilidad"
                  component={NumericFormatInput}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={
                    touched.contpaqiAccountNo && !!errors.contpaqiAccountNo
                  }
                  helperText={
                    touched.contpaqiAccountNo && errors.contpaqiAccountNo
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  as={TextField}
                  name="code"
                  label="Clave del proveedor"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.code && !!errors.code}
                  helperText={touched.code && errors.code}
                />
              </Grid>
              <Grid item xs={9}>
                <Field
                  as={TextField}
                  name="legalName"
                  label="Nombre o razón social"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.legalName && !!errors.legalName}
                  helperText={touched.legalName && errors.legalName}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  as={TextField}
                  select
                  name="providerOriginId"
                  label="Origen del proveedor"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.providerOriginId && !!errors.providerOriginId}
                  helperText={
                    touched.providerOriginId && errors.providerOriginId
                  }
                >
                  {providerOrigins.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  as={TextField}
                  select
                  name="providerTypeId"
                  label="Tipo de Proveedor"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.providerTypeId && !!errors.providerTypeId}
                  helperText={touched.providerTypeId && errors.providerTypeId}
                >
                  {providerTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  as={TextField}
                  name="taxId"
                  label="RFC / Tax ID"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.taxId && !!errors.taxId}
                  helperText={touched.taxId && errors.taxId}
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  as={TextField}
                  select
                  name="regimeId"
                  label="Régimen Fiscal"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.regimeId && !!errors.regimeId}
                  helperText={touched.regimeId && errors.regimeId}
                >
                  {regimes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  as={TextField}
                  select
                  name="subregimeId"
                  label="Subrégimen"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.subregimeId && !!errors.subregimeId}
                  helperText={touched.subregimeId && errors.subregimeId}
                >
                  {subregimes
                    .filter((e) => e.parentRegime.id === values.regimeId)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  as={TextField}
                  select
                  name="corporationTypeId"
                  label="Sociedad Moral"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled={!checkIfMoralPerson(values.providerTypeId)}
                  error={
                    touched.corporationTypeId && !!errors.corporationTypeId
                  }
                  helperText={
                    touched.corporationTypeId && errors.corporationTypeId
                  }
                >
                  {corporationTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>

            <Field
              as={TextField}
              name="descripcion"
              label="Descripción"
              variant="outlined"
              margin="normal"
              fullWidth
              error={touched.descripcion && !!errors.descripcion}
              helperText={touched.descripcion && errors.descripcion}
            />

            <Field
              as={TextField}
              name="website"
              label="Sitio Web"
              variant="outlined"
              margin="normal"
              fullWidth
              error={touched.website && !!errors.website}
              helperText={touched.website && errors.website}
            />

            <Field
              as={TextField}
              name="creditDays"
              label="Días de Crédito"
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              error={touched.creditDays && !!errors.creditDays}
              helperText={touched.creditDays && errors.creditDays}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              style={{ marginTop: "16px" }}
            >
              Enviar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
