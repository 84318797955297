import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button, Icon } from "@mui/material";

export default function LGCrudViewMode({
  id,
  onEdit,
  onDelete,
  onDetail,
  onDownload,
}) {
  return [
    onDetail && (
      <Button
        key={`detail-${id}`}
        variant="contained"
        size="small"
        onClick={(event) => onDetail(event, id)}
        sx={{ my: 2 }}
      >
        Ver mas
      </Button>
    ),
    onDownload && (
      <GridActionsCellItem
        key={`download-${id}`}
        icon={<Icon>download</Icon>}
        label="Editar"
        className="textPrimary"
        onClick={() => onDownload(id)}
        color="inherit"
      />
    ),
    onEdit && (
      <GridActionsCellItem
        key={`edit-${id}`}
        icon={<EditIcon />}
        label="Editar"
        className="textPrimary"
        onClick={() => onEdit(id)}
        color="inherit"
      />
    ),
    onDelete && (
      <GridActionsCellItem
        key={`delete-${id}`}
        icon={<DeleteIcon />}
        label="Eliminar"
        onClick={() => onDelete(id)}
        sx={{ color: "error.main" }}
      />
    ),
  ].filter(Boolean);
}
