import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import { uploadProviderContract } from "../../../api/providers";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";

// Supported file types
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/xml",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
  "image/png",
  "image/jpeg",
  "application/zip",
];

// Yup validation schema
const validationSchema = Yup.object({
  contractName: Yup.string().required("Contract name is required"),
  file: Yup.mixed()
    .required("Aun no se ha seleccionado un archivo")
    .test(
      "fileFormat",
      "Formato no aceptado",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

export default function ContractForm({ props }) {
  const { providerId } = props;
  const { openSnack } = useSnack();

  const formik = useFormik({
    initialValues: {
      contractName: "",
      providerId,
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = JSON.stringify({
        contractName: values.contractName,
        providerId,
      });
      try {
        const res = uploadProviderContract(payload, values.file);
        openSnack("Archivo subido exitosamente", "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    },
  });

  return (
    <Box sx={{ maxWidth: 500, margin: "0 auto", padding: "2rem" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="contractName"
              name="contractName"
              label="Título del contrato"
              value={formik.values.contractName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contractName &&
                Boolean(formik.errors.contractName)
              }
              helperText={
                formik.touched.contractName && formik.errors.contractName
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              id="providerId"
              name="providerId"
              label="Provider ID"
              value={formik.values.providerId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.providerId && Boolean(formik.errors.providerId)
              }
              helperText={formik.touched.providerId && formik.errors.providerId}
            />
          </Grid> */}
          <Grid item xs={12}>
            <input
              id="file"
              name="file"
              type="file"
              onChange={(event) => {
                formik.setFieldValue("file", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              accept=".pdf, .xml, .docx, .xlsx, .png, .jpg"
            />
            {formik.touched.file && formik.errors.file ? (
              <div style={{ color: "red" }}>{formik.errors.file}</div>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
