import React, { useEffect, useState } from "react";
import {
  addProviderAccounts,
  deleteProviderAccounts,
  downloadProviderContractFile,
  getProviderAccountDetail,
  getProviderAccountsList,
  getProviderContractsList,
  updateProviderAccounts,
} from "../../../api/providers";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import LGModalCrud from "../../LGCrud/LGModalCrud";
import AddProviderFromAccountForm from "../../../molecules/Providers/AddProviderForm/AddProviderFromAccount";
import UpdateProviderFromAccountForm from "../../../molecules/Providers/UpdateProviderFromAccount";
import ProviderContactList from "../ProviderContactList";
import BankAccountList from "../BankAccountList";
import ActivityTable from "../ActivityTable";
import ContractTable from "../ContractTable";
import FileUpload from "../../../screens/Lab/mockAddFile";
import ContractForm from "../ContractForm";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";

export default function CrudProviderContract({ props }) {
  const { id } = props;
  const [providerId, setProviderId] = useState("");
  const { openSnack } = useSnack();

  const handlePost = async (data) => {
    // setModalOpen(false);
    // const payload = { ...data };
    // const res = await addProviderAccounts(payload);
  };

  const handleUpdate = async (id, data) => {};

  const handleDelete = async (id) => {
    const res = await deleteProviderAccounts(id);
  };

  const handleOpenDetailClick = () => {
    console.log("Open Detail");
  };

  const handleFileDownload = async (id) => {
    try {
      const response = await downloadProviderContractFile(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloadedFile";
      console.log(contentDisposition);

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleOpenContactClick = () => {
    console.log("Open Detail");
  };
  const handleOpenBankAccountClick = () => {
    console.log("Open Detail");
  };
  const handleOpenActivitiesClick = () => {
    console.log("Open Detail");
  };

  const fetchData = async () => {
    try {
      const accountDetail = await getProviderAccountDetail(id);
      setProviderId(accountDetail.data.provider.id);
      if (accountDetail.data.provider.addressId) {
        // setAddressId(accountDetail.data.provider.addressId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box width={"100%"}>
        {providerId && (
          <LGModalCrud
            props={{
              actions: ["create", "download"],
              data: { providerId },
              fetchFunction: getProviderContractsList,
              deleteFunction: deleteProviderAccounts,
              downloadFunction: handleFileDownload,
              detailMenu: [
                {
                  label: "Descargar",
                  handler: handleFileDownload,
                  header: "Descargar",
                  body: <FileUpload />,
                },
              ],
              columns: [
                {
                  field: "contractName",
                  headerName: "Nombre del contrato",
                  flex: 0.5,
                },
              ],
              addModalData: {
                header: "Agregar Contrato",
                body: <ContractForm props={{ providerId }} />,
              },
              editModalData: {
                header: "Editar Proveedor",
                body: (
                  <UpdateProviderFromAccountForm
                    props={{ handleUpdate: handleUpdate }}
                  />
                ),
              },
            }}
          />
        )}
      </Box>
    </>
  );
}
