import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import PurchaseOrderTable from "../../organisms/PurchaseOrders/PurchaseOrderTable";
import { useSnack } from "../../services/SnakbarProvider";
import { getCompanyList } from "../../api/enterprise";
import { handleError } from "../../services/errorHandler";
import { Box, Tabs, Tab } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";

export default function PurchaseOrderScreen() {
  const [value, setValue] = useState(1);
  const [companies, setCompanies] = useState([]);
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await getCompanyList();
      setCompanies(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} sx={{}}>
          {companies.map((e) => (
            <Tab label={`ODC ${e.name}`} value={e.id} key={e.id} />
          ))}
        </Tabs>
      </Box>

      <LGTabPanel value={value} index={1}>
        <PurchaseOrderTable companyId={value} />
      </LGTabPanel>

      <LGTabPanel value={value} index={2}>
        <PurchaseOrderTable companyId={value} />
      </LGTabPanel>

      <LGTabPanel value={value} index={3}>
        <PurchaseOrderTable companyId={value} />
      </LGTabPanel>
    </DashboardLayout>
  );
}
