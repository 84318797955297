import logo from "./logo.svg";
import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import { Navigate, Route, Router, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import CompanyScreen from "./screens/Enterprise/Company";
import { getUserRoles } from "./services/auth";
import PlantList from "./organisms/Enterprise/PlantList";
import routes from "./services/routes";

function App() {
  const userData = getUserRoles();
  const roles = userData.roles || [];

  const isAuthorized = (requiredRoles) => {
    return requiredRoles.some((role) => roles.includes(role));
  };

  return (
    <Routes>
      {routes
        // .filter((r) => (r.roles ? isAuthorized(r.roles) : true))
        .map((r) => (
          <Route path={r.path} exact element={r.element} key={r.path} />
        ))}
      <Route path="*" element={<LoginScreen />} />
    </Routes>
  );
}

export default App;
