import React, { useEffect, useState } from "react";
import LGCrud from "../LGCrud";
import {
  addCurrency,
  deleteCurrency,
  getCurrencyList,
  updateCurrency,
} from "../../api/common/currencies";
import dayjs from "dayjs";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

export default function CrudCurrencies() {
  const [list, setList] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    const res = await getCurrencyList();
    setList(res.data);
  };

  const handlePost = async (data) => {
    const payload = {
      ...data,
      lastUpdate: dayjs(),
    };
    try {
      const res = await addCurrency(payload);
      openSnack("Tipo de cambio guardado con éxito", "success");
      fetchData();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = {
      ...data,
      lastUpdate: dayjs(),
    };
    try {
      const res = await updateCurrency(id, payload);
      openSnack("Tipo de cambio actualizado con éxito", "success");
      fetchData();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteCurrency(id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LGCrud
      data={list}
      columns={[
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.7,
          editable: true,
        },
        {
          field: "symbol",
          headerName: "Símbolo",
          flex: 0.3,
          editable: true,
        },
        {
          field: "exchangeRate",
          headerName: "Tipo de cambio",
          flex: 0.3,
          editable: true,
        },
        {
          field: "lastUpdate",
          headerName: "Fecha de actualización",
          flex: 0.3,
          editable: false,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
