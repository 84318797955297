import { api } from "..";

const endpoint = "/api/files";

export const uploadFile = (entityType, entityId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return api.post(`${endpoint}/upload/${entityType}/${entityId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadFile = (entityType, entityId) => {
  return api.get(`${endpoint}/${entityType}/${entityId}/contract`, {
    responseType: "blob",
    headers: { Accept: "*/*" },
  });
};
