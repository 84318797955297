import { api } from "..";

const endpoint = "/api/accounting";

// ECONOMIC ACTIVITIES
export const getEconomicActivityList = () => {
  return api.get(`${endpoint}/economic-activities/`);
};

export const addEconomicActivity = (data) => {
  return api.post(`${endpoint}/economic-activities/`, data);
};

export const updateEconomicActivity = (id, data) => {
  return api.put(`${endpoint}/economic-activities/${id}`, data);
};

export const deleteEconomicActivity = (id) => {
  return api.delete(`${endpoint}/economic-activities/${id}`);
};

// EXPENSE ACCOUNTS
export const getExpenseAccountList = () => {
    return api.get(`${endpoint}/expense-accounts/`);
  };
  
  export const addExpenseAccount = (data) => {
    return api.post(`${endpoint}/expense-accounts/`, data);
  };
  
  export const updateExpenseAccount = (id, data) => {
    return api.put(`${endpoint}/expense-accounts/${id}`, data);
  };
  
  export const deleteExpenseAccount = (id) => {
    return api.delete(`${endpoint}/expense-accounts/${id}`);
  };
