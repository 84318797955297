import React, { useState } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import dayjs from "dayjs";
import PurchaseOrderForm from "./PurchaseOrderForm";

const MOCK_getPurchaseOrderList = (queryParams) => {
  const raw_data = [
    {
      id: 1,
      providerId: "123e4567-e89b-12d3-a456-426614174000",
      plantId: 1,
      companyId: 1,
      statusId: 1,
      code: "PO-00123",
      arrivalEta: "2024-09-15",
      arrivalDate: "2024-09-20",
      requestedFrom: "Manuel Gonzalez",
      approvedBy: "Luis Yamishima",
      discount: 100.5,
      discountPercentage: 5.0,
      tax: 25.75,
      notes: "El pago será por transferencia",
      observations: "Las transferencias se procesan todos los miercoles",
      total: 1500.12,
    },
    {
      id: 2,
      providerId: "123e4567-e89b-12d3-a456-426614174000",
      plantId: 1,
      companyId: 1,
      statusId: 1,
      code: "PO-00234",
      arrivalEta: "2024-09-15",
      arrivalDate: "2024-09-20",
      requestedFrom: "Manuel Gonzalez",
      approvedBy: "Luis Yamishima",
      discount: 100.5,
      discountPercentage: 5.0,
      tax: 25.75,
      notes: "El pago será por transferencia",
      observations: "Las transferencias se procesan todos los miercoles",
      total: 1500.12,
    },
    {
      id: 3,
      providerId: "123e4567-e89b-12d3-a456-426614174000",
      plantId: 1,
      companyId: 2,
      statusId: 1,
      code: "PO-00345",
      arrivalEta: "2024-09-15",
      arrivalDate: "2024-09-20",
      requestedFrom: "Manuel Gonzalez",
      approvedBy: "Luis Yamishima",
      discount: 100.5,
      discountPercentage: 5.0,
      tax: 25.75,
      notes: "El pago será por transferencia",
      observations: "Las transferencias se procesan todos los miercoles",
      total: 1500.12,
    },
  ];

  const data = raw_data.filter((e) => e.companyId == queryParams.companyId);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data });
    }, 100);
  });
};

export default function PurchaseOrderTable({ companyId }) {
  return (
    <Box width={"100%"}>
      <LGModalCrud
        props={{
          actions: ["create", "update", "delete", "detail"],
          data: { companyId },
          fetchFunction: MOCK_getPurchaseOrderList,
          deleteFunction: () => {},
          //   detailMenu: [
          //     {
          //       label: "Documentos",
          //       handler: () => {},
          //       header: "Documentos",
          //       body: <UpdateFileForm />,
          //     },
          //   ],
          columns: [
            {
              field: "code",
              headerName: "Código",
              flex: 0.2,
            },
            {
              field: "arrivalEta",
              headerName: "Fecha estimada de llegada",
              flex: 0.2,
            },
            {
              field: "requestedFrom",
              headerName: "Pedido por",
              flex: 0.2,
            },
          ],
          addModalData: {
            header: "Agregar Órden de compra",
            body: <PurchaseOrderForm />,
          },
          editModalData: {
            header: "Editar Órden de compra",
            body: <PurchaseOrderForm />,
          },
        }}
      />
    </Box>
  );
}
