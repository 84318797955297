import { Box, Divider, Grid, Icon, Stack, Typography } from "@mui/material";
import React from "react";

export default function BankAccountItem({ props }) {
  const { bankAccount, clabe, cie, currency, bank } = props;
  return (
    <Box mt={4}>
      <Typography variant="h6">{bank.name}</Typography>
      <Box m={1} />
      <Grid container>
        <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent={"space-around"}
          >
            <Icon>account_balance</Icon>
            <Typography variant="button" color={"primary.main"}>
              Cuenta
            </Typography>
            <Typography>{bankAccount}</Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent={"space-around"}
          >
            <Icon>vpn_key</Icon>
            <Typography variant="button" color={"primary.main"}>
              CLABE
            </Typography>
            <Typography>{clabe}</Typography>
          </Stack>
        </Grid>

        <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent={"space-around"}
          >
            <Icon>currency_exchange</Icon>
            <Typography variant="button" color={"primary.main"}>
              Moneda
            </Typography>
            <Typography variant="button" color={"success.main"}>
              {currency.symbol}
            </Typography>
            <Typography>{currency.name}</Typography>
          </Stack>
        </Grid>

        {/* <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent={"space-around"}
          >
            <Icon>money</Icon>
            <Typography variant="button" color={"primary.main"}>
              No. de Contabilidad
            </Typography>
            <Typography>{contpaqiAccountNo}</Typography>
          </Stack>
        </Grid> */}
        {cie && (
          <Grid container item xs={12}>
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              justifyContent={"space-evenly"}
            >
              <Icon>compare_arrows</Icon>
              <Typography variant="button" color={"primary.main"}>
                Cuenta CIE
              </Typography>
              <Typography>{cie}</Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
