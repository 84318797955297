import React, { useEffect, useState } from "react";
import {
  addProviderAccounts,
  deleteProviderAccounts,
  getProviderAccountsList,
  updateProviderAccounts,
} from "../../../api/providers";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import LGModalCrud from "../../LGCrud/LGModalCrud";
import AddProviderFromAccountForm from "../../../molecules/Providers/AddProviderForm/AddProviderFromAccount";
import UpdateProviderFromAccountForm from "../../../molecules/Providers/UpdateProviderFromAccount";
import ProviderContactList from "../ProviderContactList";
import BankAccountList from "../BankAccountList";
import ActivityTable from "../ActivityTable";
import ContractTable from "../ContractTable";
import CrudProviderContract from "../ContractTable";

export default function CrudProviderAccounts({ props }) {
  const { companyId } = props;

  return (
    <>
      <Box width={"100%"}>
        <LGModalCrud
          props={{
            actions: ["create", "update", "delete", "detail"],
            data: { companyId },
            fetchFunction: getProviderAccountsList,
            deleteFunction: deleteProviderAccounts,
            detailMenu: [
              {
                label: "Dirección y Contacto",
                handler: () => {},
                header: "Contactos del proveedor",
                body: <ProviderContactList />,
              },
              {
                label: "Info. Bancaria",
                handler: () => {},
                header: "Información bancaria",
                body: <BankAccountList />,
              },
              {
                label: "Actividades",
                handler: () => {},
                header: "Actividades del proveedor",
                body: <ActivityTable />,
              },
              {
                label: "Contratos",
                handler: () => {},
                header: "Lista de contratos",
                body: <CrudProviderContract />,
              },
            ],
            columns: [
              {
                field: "contpaqiAccountNo",
                headerName: "Cuenta",
                flex: 0.2,
              },
              {
                field: "code",
                headerName: "Clave",
                flex: 0.2,
                valueGetter: (value, row) => {
                  return row.provider.code || "ERROR AL OBTENER NOMBRE";
                },
              },
              {
                field: "legalName",
                headerName: "Nombre",
                flex: 0.5,
                valueGetter: (value, row) => {
                  return row.provider.legalName || "ERROR AL OBTENER NOMBRE";
                },
              },
            ],
            addModalData: {
              header: "Agregar Proveedor",
              body: <AddProviderFromAccountForm />,
            },
            editModalData: {
              header: "Editar Proveedor",
              body: <UpdateProviderFromAccountForm />,
            },
          }}
        />
      </Box>
    </>
  );
}
