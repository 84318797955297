import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { addProvider } from "../../../api/providers";
import { getCatalogue, getSubregimeList } from "../../../api/common/catalogues";

// Validation schema
const validationSchema = yup.object({
  legalName: yup
    .string("Ingrese la Razón Social")
    .required("La Razón Social es obligatoria"),
  code: yup.string("Ingrese la clave").required("La clave es obligatorio"),
  taxId: yup
    .string("Ingrese el RFC/ID fiscal")
    .required("El RFC/ID fiscal es obligatorio"),
  providerTypeId: yup
    .string("Seleccione el tipo de proveedor")
    .required("El tipo de proveedor es obligatorio"),
  corporationTypeId: yup.string("Seleccione el tipo de sociedad Moral"),
  subregimeId: yup
    .string("Seleccione el tipo de Subrégimen")
    .required("El tipo de subrégimen es obligatorio"),
  descripcion: yup
    .string("Ingrese la descripción")
    .required("La descripción es obligatoria"),
  website: yup
    .string("Ingrese el sitio web")
    .matches(
      /^(https?:\/\/)?([\w\d-]+)\.([\w\d-]+)([\w\d-./?%&=]*)?$/,
      "Ingrese un sitio web válido"
    )
    .required("El sitio web es obligatorio"),
  creditDays: yup
    .number("Ingrese los días de crédito")
    .min(0, "Los días de crédito deben ser mayores o iguales a 0")
    .required("Los días de crédito son obligatorios"),
});

export default function AddProviderForm({ props }) {
  const { handleAddProvider } = props;
  const [providerTypes, setProviderTypes] = useState([]);
  const [subRegimes, setSubregimes] = useState([]);
  const [corporationTypes, setCorporationTypes] = useState([]);

  const fetchData = async () => {
    const res = await getCatalogue();
    setProviderTypes(
      res.data.filter((e) => e.catalogueName == "PROVIDER_TYPE")
    );
    setCorporationTypes(
      res.data.filter((e) => e.catalogueName == "CORPORATION_TYPE")
    );
    const subregimesData = await getSubregimeList();
    setSubregimes(subregimesData.data);
  };

  const formik = useFormik({
    initialValues: {
      legalName: "",
      code: "",
      taxId: "",
      providerTypeId: "",
      corporationTypeId: "",
      subregimeId: "",
      descripcion: "",
      website: "",
      creditDays: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = await addProvider(values);
      handleAddProvider();
    },
  });

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ mt: 3 }}
      noValidate
      autoComplete="off"
    >
      <TextField
        fullWidth
        id="legalName"
        name="legalName"
        label="Nombre o Razón Social"
        value={formik.values.legalName}
        onChange={formik.handleChange}
        error={formik.touched.legalName && Boolean(formik.errors.legalName)}
        helperText={formik.touched.legalName && formik.errors.legalName}
        margin="normal"
      />
      <TextField
        fullWidth
        id="code"
        name="code"
        label="Clave"
        value={formik.values.code}
        onChange={formik.handleChange}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
        margin="normal"
      />
      <TextField
        fullWidth
        id="taxId"
        name="taxId"
        label="RFC / ID Fiscal"
        value={formik.values.taxId}
        onChange={formik.handleChange}
        error={formik.touched.taxId && Boolean(formik.errors.taxId)}
        helperText={formik.touched.taxId && formik.errors.taxId}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="providerTypeId-label">Tipo de Proveedor</InputLabel>
        <Select
          labelId="providerTypeId-label"
          id="providerTypeId"
          name="providerTypeId"
          value={formik.values.providerTypeId}
          onChange={formik.handleChange}
          error={
            formik.touched.providerTypeId &&
            Boolean(formik.errors.providerTypeId)
          }
        >
          {providerTypes.map((e) => (
            <MenuItem value={e.id} key={e.id}>
              {e.code} | {e.name}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.providerTypeId && formik.errors.providerTypeId && (
          <Box color="error.main" mt={0.5}>
            {formik.errors.providerTypeId}
          </Box>
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="subregimeId-label">Subrégimen</InputLabel>
        <Select
          labelId="subregimeId-label"
          id="subregimeId"
          name="subregimeId"
          value={formik.values.subregimeId}
          onChange={formik.handleChange}
          error={
            formik.touched.subregimeId && Boolean(formik.errors.subregimeId)
          }
        >
          {subRegimes.map((e) => (
            <MenuItem value={e.id} key={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.subregimeId && formik.errors.subregimeId && (
          <Box color="error.main" mt={0.5}>
            {formik.errors.subregimeId}
          </Box>
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="corporationTypeId-label">
          Tipo de Sociedad Moral
        </InputLabel>
        <Select
          labelId="corporationTypeId-label"
          id="corporationTypeId"
          name="corporationTypeId"
          value={formik.values.corporationTypeId}
          onChange={formik.handleChange}
          error={
            formik.touched.corporationTypeId &&
            Boolean(formik.errors.corporationTypeId)
          }
        >
          {corporationTypes.map((e) => (
            <MenuItem value={e.id} key={e.id}>
              {e.code} | {e.name}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.corporationTypeId &&
          formik.errors.corporationTypeId && (
            <Box color="error.main" mt={0.5}>
              {formik.errors.corporationTypeId}
            </Box>
          )}
      </FormControl>

      <TextField
        fullWidth
        id="descripcion"
        name="descripcion"
        label="Descripción de las actividades principales"
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
        helperText={formik.touched.descripcion && formik.errors.descripcion}
        margin="normal"
      />
      <TextField
        fullWidth
        id="website"
        name="website"
        label="Sitio Web"
        value={formik.values.website}
        onChange={formik.handleChange}
        error={formik.touched.website && Boolean(formik.errors.website)}
        helperText={formik.touched.website && formik.errors.website}
        margin="normal"
      />
      <TextField
        fullWidth
        id="creditDays"
        name="creditDays"
        label="Días de Crédito"
        type="number"
        value={formik.values.creditDays}
        onChange={formik.handleChange}
        error={formik.touched.creditDays && Boolean(formik.errors.creditDays)}
        helperText={formik.touched.creditDays && formik.errors.creditDays}
        margin="normal"
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: 2 }}
      >
        Gaurdar
      </Button>
    </Box>
  );
}
