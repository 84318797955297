import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";

import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { LGCrudToolbar } from "../../../molecules/LGCrud/toolbar";
import LGCrudViewMode from "../../../molecules/LGCrud/viewMode";
import LGCrudEditMode from "../../../molecules/LGCrud/editMode";
import {
  addProvider,
  deleteProvider,
  getProviderAccountsList,
  getProviderBankDetailList,
  getProviderContactList,
  getProviderList,
  updateProvider,
} from "../../../api/providers";
import { Chip, Menu, MenuItem } from "@mui/material";
import LGModal from "../../../molecules/LGModal";
import ProviderContactList from "../ProviderContactList";
import BankAccountList from "../BankAccountList";
import ActivityTable from "../ActivityTable";
import ProviderDetail from "../ProviderDetail";
import ProviderTableToolbar from "./toolbar";
import AddProviderForm from "../../../molecules/Providers/AddProviderForm";
import CrudProviderAccounts from "../AccountList/CrudProviderAccounts";

// TODO: Refactorizar componente
export default function ProviderTable() {
  // TABLE DATA
  const [rows, setRows] = useState([]);
  const [queryOptions, setQueryOptions] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectedProviderId, setSelectedProviderId] = useState("");

  const fetchData = async () => {
    const resP = await getProviderList(queryOptions);
    if (resP.data) {
      setRows(resP.data);
    }
  };

  const fetchDetails = async (id, fetchFunction, header, Component) => {
    const { data } = await fetchFunction(id);
    setModalOpen(true);
    setModalData({
      header: header,
      body: (
        <Component
          props={{ data: data || {}, providerId: selectedProviderId }}
        />
      ),
    });
  };

  const handleAddProvider = () => {
    fetchData();
    setModalOpen(false);
  };

  const handleAddProviderClick = () => {
    setModalOpen(true);
    setModalData({
      header: "Nuevo proveedor",
      body: <AddProviderForm props={{ handleAddProvider }} />,
    });
  };

  // FILTER & SORTING

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
    const queryParams = filterModel.items.map((e) => ({ [e.field]: e.value }));
    console.log(queryParams);
  });

  // MODAL
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ header: "", body: <></> });

  const handleModalClose = () => {
    setModalOpen(false);
    handleClose();
  };

  // Context Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenDetailClick = () => {
    setModalOpen(true);
    setModalData({ header: "Detalle del proveedor", body: <ProviderDetail /> });
  };

  const handleOpenAccountsClick = () => {
    fetchDetails(
      selectedProviderId,
      getProviderAccountsList,
      "Información de las cuentas  contables",
      CrudProviderAccounts
    );
  };

  const handleOpenContactClick = () => {
    fetchDetails(
      selectedProviderId,
      getProviderContactList,
      "Información de contacto del proveedor",
      ProviderContactList
    );
  };

  const handleOpenBankAccountClick = async () => {
    fetchDetails(
      selectedProviderId,
      getProviderBankDetailList,
      "Información bancaria del proveedor",
      BankAccountList
    );
  };

  const handleOpenActivitiesClick = async () => {
    setModalOpen(true);
    setModalData({
      header: "Actividades fiscalesy contables",
      body: <ActivityTable props={{ providerId: selectedProviderId }} />,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TABLE HANDELERS

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleShowDetailClick = (id) => (event) => {
    setSelectedProviderId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = (id) => async () => {
    const res = await deleteProvider(id);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const payload = { ...newRow };
    delete payload.id;

    // Add new
    if (newRow.isNew) {
      const res = await addProvider(payload);
    }
    // Edit Existing
    else {
      const res = await updateProvider(newRow.id, payload);
    }

    const updatedRow = { ...newRow, isNew: false };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "code", headerName: "Clave", flex: 0.1, editable: false },
    {
      field: "legalName",
      headerName: "Razón Social",
      flex: 0.3,
      editable: true,
    },
    {
      field: "descripcion",
      headerName: "Descripción de actividades",
      flex: 0.35,
      editable: true,
    },
    { field: "taxId", headerName: "RFC/TaxID", flex: 0.15, editable: true },
    {
      field: "providerType",
      headerName: "Tipo Proveedor",
      flex: 0.1,
      editable: false,
      renderCell: (params) => (
        <Chip
          size="small"
          label={params.row.providerType.name}
          color={
            params.row.providerType.name == "Persona Física"
              ? "primary"
              : params.row.providerType.name == "Persona Moral"
              ? "success"
              : "secondary"
          }
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <LGCrudEditMode
              id={id}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
            />,
          ];
        }

        return [
          <LGCrudViewMode
            id={id}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            onDetail={handleShowDetailClick}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <LGModal
        open={modalOpen}
        header={modalData.header}
        handleClose={handleModalClose}
      >
        {modalData.body}
      </LGModal>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disabled onClick={handleOpenDetailClick}>
          Detalle completo
        </MenuItem>
        <MenuItem onClick={handleOpenContactClick}>Contacto</MenuItem>
        <MenuItem onClick={handleOpenAccountsClick}>Cuentas</MenuItem>
        <MenuItem onClick={handleOpenBankAccountClick}>Info bancaria</MenuItem>
        <MenuItem onClick={handleOpenActivitiesClick}>Actividades</MenuItem>
      </Menu>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        // getEstimatedRowHeight={() => 70}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: ProviderTableToolbar,
        }}
        slotProps={{
          toolbar: { handleAddProviderClick },
        }}
        filterMode="server"
        paginationMode="server"
        onFilterModelChange={onFilterChange}
      />
    </Box>
  );
}
