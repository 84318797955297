import React, { useEffect, useState } from "react";
import {
  addTaxRegime,
  deleteTaxRegime,
  getCatalogue,
  getTaxRegimeList,
  updateTaxRegime,
} from "../../api/common/catalogues";
import LGCrud from "../LGCrud";
import LGSelectCellEditor from "../LGCrud/LGSelectCellEditor";
import LGSelectCellRenderer from "../LGCrud/LGSelectCellRenderer";
import { percentageFormatter } from "../../services/valueFormatters";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

export default function CrudProviderTaxRegime() {
  const { openSnack } = useSnack();
  const [taxRegimes, setTaxRegimes] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTaxRegimes = async () => {
    const res = await getTaxRegimeList();
    setTaxRegimes(res.data);
  };

  const fetchProviderSubtypes = async () => {
    try {
      const res = await getCatalogue("PROVIDER_TYPE");
      setProviderTypes(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.providerTypeId = payload.providerType.id;
    try {
      const res = await addTaxRegime(payload);
      openSnack("Régimen agregado con éxito", "sucess");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    payload.providerTypeId = payload.providerType.id;
    try {
      const res = await updateTaxRegime(id, payload);
      openSnack("Régimen actualizado con éxito", "sucess");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = async (id) => {
    console.log("Delete ID", id);
    try {
      const res = await deleteTaxRegime(id);
      openSnack("Régimen eliminado con éxito", "sucess");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      fetchTaxRegimes();
      fetchProviderSubtypes();
    } catch (e) {
      openSnack(handleError(e), "error");
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <LGCrud
      data={taxRegimes}
      columns={[
        {
          field: "providerType",
          headerName: "Subtipo de proveedor",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => v.name}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={providerTypes}
              labelTemplate={(v) => v.name}
            />
          ),
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
        {
          field: "taxIva",
          headerName: "IVA retenido",
          flex: 0.5,
          editable: true,
          ...percentageFormatter,
        },
        {
          field: "taxIsr",
          headerName: "ISR retenido",
          flex: 0.5,
          editable: true,
          ...percentageFormatter,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
