import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'

export default function HomeScreen() {
  return (
	<DashboardLayout>
		<p>Wiu</p>
	</DashboardLayout>
)
}
