import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Typography } from "@mui/material";
import { downloadFile, uploadFile } from "../../api/common/filemanager";
import DashboardLayout from "../../layouts/DashboardLayout";
import { downloadProviderContractFile } from "../../api/providers";

const FileUpload = ({ entityType, entityId }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    try {
      const response = await uploadFile("providerContract", 2, selectedFile);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileDownload = async () => {
    try {
      const response = await downloadProviderContractFile(2);

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fileName"); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleFileDownload}>
        Descarga
      </Button>
    </div>
  );
};

export default FileUpload;
