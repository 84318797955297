import React from "react";
import { useFormikContext } from "formik";
import { TextField, Button, MenuItem, Grid } from "@mui/material";

const fileOptions = [
  "Guia de embarque",
  "Lista de empaque",
  "Factura Proveedor",
  "Pedimento",
  "Cuenta de Gastos (ZIP)",
];

const ShipmentInputFile = ({ name }) => {
  const { setFieldValue, getFieldProps, touched, errors } = useFormikContext();

  const typeFieldProps = getFieldProps(`${name}.fileConcept`);
  const fileError = errors[name]?.file;
  const fileTouched = touched[name]?.file;

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue(`${name}.file`, file);
  };

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={9}>
        <TextField
          select
          label="Selecionar..."
          {...typeFieldProps}
          error={Boolean(touched[name]?.type && errors[name]?.type)}
          helperText={touched[name]?.type && errors[name]?.type}
          fullWidth
        >
          {fileOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" component="label" fullWidth>
          Subir Archivo
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        {fileTouched && fileError && (
          <div style={{ color: "red", fontSize: "0.8em" }}>{fileError}</div>
        )}
      </Grid>
    </Grid>
  );
};

export default ShipmentInputFile;
