// AddBankDetailForm.jsx
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { addProviderBankDetail } from "../../../api/providers";
import { getCatalogue } from "../../../api/common/catalogues";

// Validation schema
const validationSchema = yup.object({
  bankId: yup.string("Seleccione el banco").required("El banco es obligatorio"),
  contpaqiAccountNo: yup
    .string("Ingrese el número de contabilidad")
    .matches(
      /^\d{12}$/,
      "Ingrese un número de cuenta Contpaqi válido (000000000000)"
    )
    .required("El número de contabilidad es obligatorio"),
  bankAccount: yup
    .string("Ingrese el número de cuenta bancaria")
    .required("El número de cuenta bancaria es obligatorio"),
  clabe: yup.string("Ingrese la CLABE").required("La CLABE es obligatoria"),
  cie: yup.string("Ingrese el CIE"),
  cieRef: yup.string("Ingrese la referencia CIE"),
  currencyId: yup
    .number("Seleccione la moneda")
    .required("La moneda es obligatoria"),
});

export default function AddBankDetailForm({ props }) {
  const { providerId, handleModalClose } = props;
  const [bankList, setBankList] = useState([]);

  const submitForm = async (data) => {
    const payload = { ...data, providerId };
    const res = await addProviderBankDetail(payload);
    // Handle the response as needed
  };

  const formik = useFormik({
    initialValues: {
      bankId: "",
      contpaqiAccountNo: "111111111111",
      bankAccount: "",
      clabe: "",
      cie: "",
      cieRef: "",
      currencyId: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitForm(values);
      handleModalClose();
    },
  });

  const fetchData = async () => {
    try {
      const res = await getCatalogue("BANKS");
      setBankList(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ mt: 3 }}
      noValidate
      autoComplete="off"
    >
      <FormControl fullWidth margin="normal">
        <InputLabel id="bankName-label">Banco</InputLabel>
        <Select
          labelId="bankName-label"
          id="bankId"
          name="bankId"
          value={formik.values.bankId}
          onChange={formik.handleChange}
          error={formik.touched.bankId && Boolean(formik.errors.bankId)}
        >
          {bankList.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.code} | {e.name}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.bankId && formik.errors.bankId && (
          <Box color="error.main" mt={0.5}>
            {formik.errors.bankId}
          </Box>
        )}
      </FormControl>
      <TextField
        fullWidth
        id="bankAccount"
        name="bankAccount"
        label="Número de Cuenta Bancaria"
        value={formik.values.bankAccount}
        onChange={formik.handleChange}
        error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}
        helperText={formik.touched.bankAccount && formik.errors.bankAccount}
        margin="normal"
      />
      <TextField
        fullWidth
        id="clabe"
        name="clabe"
        label="CLABE"
        value={formik.values.clabe}
        onChange={formik.handleChange}
        error={formik.touched.clabe && Boolean(formik.errors.clabe)}
        helperText={formik.touched.clabe && formik.errors.clabe}
        margin="normal"
      />
      <TextField
        fullWidth
        id="cie"
        name="cie"
        label="CIE"
        value={formik.values.cie}
        onChange={formik.handleChange}
        error={formik.touched.cie && Boolean(formik.errors.cie)}
        helperText={formik.touched.cie && formik.errors.cie}
        margin="normal"
      />
      <TextField
        fullWidth
        id="cieRef"
        name="cieRef"
        label="CIE REF."
        value={formik.values.cieRef}
        onChange={formik.handleChange}
        error={formik.touched.cieRef && Boolean(formik.errors.cieRef)}
        helperText={formik.touched.cieRef && formik.errors.cieRef}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="currencyId-label">Moneda</InputLabel>
        <Select
          labelId="currencyId-label"
          id="currencyId"
          name="currencyId"
          value={formik.values.currencyId}
          onChange={formik.handleChange}
          error={formik.touched.currencyId && Boolean(formik.errors.currencyId)}
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem>
          <MenuItem value={1}>MXN</MenuItem>
          <MenuItem value={2}>USD</MenuItem>
          <MenuItem value={3}>EUR</MenuItem>
        </Select>
        {formik.touched.currencyId && formik.errors.currencyId && (
          <Box color="error.main" mt={0.5}>
            {formik.errors.currencyId}
          </Box>
        )}
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: 2 }}
      >
        Enviar
      </Button>
    </Box>
  );
}
