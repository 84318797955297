import { api } from "..";

const endpoint = "/api/products";

// PRODUCTS
export const getProductList = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const addProduct = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateProduct = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteProduct = (id) => {
  return api.delete(`${endpoint}/${id}`);
};
