import React, { useEffect, useState } from "react";
import {
  addSubregime,
  deleteSubregime,
  getSubregimeList,
  getTaxRegimeList,
  updateSubregime,
} from "../../api/common/catalogues";
import LGCrud from "../LGCrud";
import LGSelectCellRenderer from "../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../LGCrud/LGSelectCellEditor";

export default function CrudProviderSubregime() {
  const [subregimes, setSubregimes] = useState([]);
  const [taxRegimes, setTaxRegimes] = useState([]);

  const fetchSubregimes = async () => {
    const res = await getSubregimeList();
    setSubregimes(res.data);
  };

  const fetchTaxRegimes = async () => {
    const res = await getTaxRegimeList();
    setTaxRegimes(res.data);
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.parentRegimeId = payload.parentRegime.id;
    const res = await addSubregime(payload);
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    payload.parentRegimeId = payload.parentRegime.id;
    const res = await updateSubregime(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteSubregime(id);
  };

  useEffect(() => {
    fetchSubregimes();
    fetchTaxRegimes();
  }, []);
  return (
    <LGCrud
      data={subregimes}
      columns={[
        {
          field: "parentRegime",
          headerName: "Régimen principal",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
          renderEditCell: (params) => (
            console.log(params),
            (
              <LGSelectCellEditor
                {...params}
                dropdownData={taxRegimes}
                labelTemplate={(v) => v.name}
              />
            )
          ),
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
