import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { LGCrudToolbar } from "../../../molecules/LGCrud/toolbar";
import LGCrudViewMode from "../../../molecules/LGCrud/viewMode";
import LGCrudEditMode from "../../../molecules/LGCrud/editMode";
import { addPlant, deletePlant, updatePlant } from "../../../api/enterprise";

export default function PlantList({ data }) {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    const res = await deletePlant(id);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const payload = (({ name }) => ({ name }))(newRow);

    // Add new
    if (newRow.isNew) {
      const res = await addPlant(payload);
    }
    // Edit Existing
    else {
      const res = await updatePlant(newRow.id, payload);
    }

    const updatedRow = { ...newRow, isNew: false };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "id", headerName: "ID", width: .05, editable: true },
    { field: "code", headerName: "Clave", flex: 0.1, editable: true },
    { field: "name", headerName: "Nombre", flex: 0.15, editable: true },
    {
      field: "company.name",
      valueGetter: (value, row) => {
        return row.company.name;
      },
      headerName: "Empresa",
      flex: 0.2,
      editable: false,
    },
    {
      field: "address.short",
      valueGetter: (value, row) => {
        var address = row.address
        return address ?`${address.street} ${address.outNum} ${address.innNum}`: "N/A";
      },
      headerName: "Dirección",
      flex: 0.5,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <LGCrudEditMode
              id={id}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
            />,
          ];
        }

        return [
          <LGCrudViewMode
            id={id}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: LGCrudToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}
