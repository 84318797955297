// NumericFormatInput.js
import React from "react";
import { TextField } from "@mui/material";

const formatValue = (value) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{0,4})(\d{0,4})(\d{0,4})$/);
  if (match) {
    return [match[1], match[2], match[3]].filter(Boolean).join("-");
  }
  return value;
};

const NumericFormatInput = ({ field, form, ...props }) => {
  const handleChange = (event) => {
    const value = event.target.value.replace(/-/g, "");
    form.setFieldValue(field.name, value);
  };

  return (
    <TextField
      {...field}
      {...props}
      value={field ? formatValue(field.value) : ""}
      onChange={handleChange}
    />
  );
};

export default NumericFormatInput;
