import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LGAppBar from "../../organisms/LGAppBar";
import { useNavigate } from "react-router-dom";
import { getUserRoles } from "../../services/auth";
import { Icon, IconButton } from "@mui/material";
import routes from "../../services/routes";
import { DrawerHeader } from "../../atoms/DrawerHeader";

const drawerWidth = 240;

export default function DashboardLayout({ children }) {
  const navigate = useNavigate();

  const userData = getUserRoles();
  const roles = userData.roles || [];
  const [open, setOpen] = useState(true);

  const isAuthorized = (requiredRoles) => {
    return requiredRoles.some((role) => roles.includes(role));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <LGAppBar open={open} handleopenDrawer={handleDrawerOpen} />

      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            size="large"
            sx={{ color: "white" }}
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {roles &&
            routes
              .filter((e) => e.menu && (e.roles ? isAuthorized(e.roles) : true))
              .map((e) => (
                <ListItem key={e.menu.title}>
                  <ListItemButton onClick={() => navigate(e.path)}>
                    <ListItemIcon>{e.menu.icon}</ListItemIcon>
                    <ListItemText primary={e.menu.title} />
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          mt: 8,
          flexGrow: 1,
          transition: "margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
          marginLeft: `-${drawerWidth}px`,
          ...(open && {
            marginLeft: 0,
            transition: "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
