import { React, useEffect, useState } from "react";
import ContactItem from "../../../molecules/Providers/ContactItem";
import AddressForm from "../../../molecules/Common/AddressForm";
import { Button, Grid, Icon, Typography } from "@mui/material";
import AddContactForm from "../../../molecules/Providers/AddContactForm";
import LGModal from "../../../molecules/LGModal";
import {
  addProviderAddress,
  getProviderAccountDetail,
  getProviderAddressesList,
  getProviderContactList,
  patchProvider,
  updateProvider,
} from "../../../api/providers";
import AddressCard from "../../../molecules/Common/AddressCard";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";

export default function ProviderContactList({ props }) {
  const { id } = props;
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [contactList, setContactList] = useState([]);
  const [addressesList, setAddressesList] = useState([]);
  const [providerId, setProviderId] = useState("");

  const { openSnack } = useSnack();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddContact = () => {
    setOpenModal(false);
    fetchData();
  };

  const handleAddProviderClick = () => {
    setModalData({
      header: "Agregar Contacto",
      body: <AddContactForm props={{ providerId, handleAddContact }} />,
    });
    setOpenModal(true);
  };

  const handleAddAddressClick = () => {
    setModalData({
      header: "Agregar Dirección",
      body: <AddressForm props={{ id: 0, onSubmit: handleSubmitAddress }} />,
    });
    setOpenModal(true);
  };

  const handleSubmitAddress = (addressPayload) => {
    const payload = { ...addressPayload, providerId };
    const res = addProviderAddress(payload);
  };

  const fetchData = async () => {
    try {
      const accountDetail = await getProviderAccountDetail(id);
      setProviderId(accountDetail.data.provider.id);
      if (accountDetail.data.provider.addressId) {
        // setAddressId(accountDetail.data.provider.addressId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchContacts = async () => {
    try {
      const res = await getProviderContactList(providerId);
      setContactList(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAddresses = async () => {
    try {
      const res = await getProviderAddressesList(providerId);
      setAddressesList(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (providerId) {
      fetchContacts();
      fetchAddresses();
    }
  }, [providerId]);

  return (
    <>
      <LGModal
        open={openModal}
        header={modalData.header}
        handleClose={handleModalClose}
      >
        {modalData.body}
      </LGModal>

      <Grid
        container
        mt={4}
        spacing={2}
        justifyContent={"left"}
        alignItems={"stretch"}
      >
        <Grid item xs={9}>
          <Typography variant="h4">Direcciones</Typography>
        </Grid>
        <Grid justifySelf={"left"} item xs={3}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<Icon>add</Icon>}
            onClick={handleAddAddressClick}
          >
            Agregar
          </Button>
        </Grid>
        <Grid container spacing={2} item xs={12} alignContent={"stretch"}>
          {addressesList &&
            addressesList.map((e, index) => (
              <Grid item xs={4} key={index}>
                <AddressCard address={e.address} />
              </Grid>
            ))}
        </Grid>

        <Grid item xs={9}>
          <Typography variant="h4">Contacto</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<Icon>add</Icon>}
            onClick={handleAddProviderClick}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      {contactList &&
        contactList.map((e, index) => <ContactItem key={index} props={e} />)}
    </>
  );
}
