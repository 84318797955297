import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function LGCrudToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = -1 * getRandomInt(1000);
    setRows((oldRows) => [...oldRows, { id, name: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Agregar
      </Button>
    </GridToolbarContainer>
  );
}
