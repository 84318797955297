import { Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

export default function ProviderTableToolbar(props) {
  const { handlePost } = props;
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handlePost}
      >
        Agregar
      </Button>
    </GridToolbarContainer>
  );
}
