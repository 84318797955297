import { jwtDecode } from "jwt-decode";

export const getUserRoles = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);

    return { user: _.given_name, roles: _.roles ? JSON.parse(_.roles) : [] };
  }
  return { user: "", roles: [] };
};
