import React from "react";
import PlantList from "../organisms/Enterprise/PlantList";
import LoginScreen from "../screens/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import CompanyScreen from "../screens/Enterprise/Company";
import { Box, Icon } from "@mui/material";
import HomeScreen from "../screens/Home";
import ProviderScreen from "../screens/Providers";
import CataloguesScreen from "../screens/Catalogues";
import ProviderTable from "../organisms/Providers/ProviderTable";
import MockAddProduct from "../screens/Products/mockAddProduct";
import ProductScreen from "../screens/Products";
import FileUpload from "../screens/Lab/mockAddFile";
import ShipmentScreen from "../screens/Imports/ShipmentScreen";
import DashboardLayout from "../layouts/DashboardLayout";
import PurchaseOrderForm from "../organisms/PurchaseOrders/PurchaseOrderForm";
import PurchaseOrderScreen from "../screens/PurchaseOrders/PurchaseOrderScreen";

const baseRoles = ["Superadmin", "Admin"];

const ENV = process.env.REACT_APP_ENV;

const routes = [
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/register",
    element: <RegisterScreen />,
    roles: ["Superadmin"],
    menu: {
      title: "Registrar usuario",
      icon: <Icon>person_add</Icon>,
    },
  },
  {
    path: "/home",
    element: <HomeScreen />,
    roles: baseRoles,
  },
  {
    path: "/providers",
    element: <ProviderScreen />,
    roles: baseRoles,
    menu: {
      title: "Proveedores",
      icon: <Icon>warehouse</Icon>,
    },
  },
  {
    path: "/products",
    element: <ProductScreen />,
    roles: baseRoles,
    menu: {
      title: "Productos",
      icon: <Icon>business_center</Icon>,
    },
  },
  {
    path: "/purchase-orders",
    element: <PurchaseOrderScreen />,
    roles: baseRoles,
    menu: {
      title: "Órdenes de compra",
      icon: <Icon>shopping_bag</Icon>,
    },
  },
  {
    path: "/inventory",
    element: <ProviderTable />,
    roles: baseRoles,
    menu: {
      title: "Inventarios",
      icon: <Icon>inventory</Icon>,
    },
  },

  {
    path: "/imports",
    element: <ShipmentScreen />,
    roles: [...baseRoles],
    menu: {
      title: "Importaciones",
      icon: <Icon>flight_land</Icon>,
    },
  },

  {
    path: "/plants",
    element: <PlantList />,
    roles: [...baseRoles, "User"],
  },
  {
    path: "/enterprise",
    element: <CompanyScreen />,
    roles: ["Superadmin"],
    menu: {
      title: "Empresa",
      icon: <Icon>factory</Icon>,
    },
  },
  {
    path: "/catalogues",
    element: <CataloguesScreen />,
    roles: baseRoles,
    menu: {
      title: "Catálogos",
      icon: <Icon>all_inbox</Icon>,
    },
  },
  {
    path: "/unauthorized",
    element: <PlantList />,
    roles: ["User"],
  },
];

if (ENV == "LOCAL") {
  routes.push({
    path: "/lab",
    element: (
      <DashboardLayout>
        <PurchaseOrderForm />
      </DashboardLayout>
    ),
    roles: baseRoles,
    menu: {
      title: "Laboratorio",
      icon: <Icon>science</Icon>,
    },
  });
}

export default routes;
