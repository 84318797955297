import React, { useEffect, useState } from "react";
import {
  addCatalogueEntry,
  deleteCatalogueEntry,
  getCatalogue,
  updateCatalogueEntry,
} from "../../api/common/catalogues";
import LGCrud from "../LGCrud";

export default function CrudImportShipmentMode() {
  const [list, setList] = useState([]);

  const fetchData = async () => {
    const res = await getCatalogue("SHIPMENT_MODE");
    setList(res.data);
  };

  const handlePost = async (data) => {
    const payload = {
      ...data,
      CatalogueName: "SHIPMENT_MODE",
      Props: "{}",
    };
    const res = await addCatalogueEntry(payload);
  };

  const handleUpdate = async (id, data) => {
    const payload = {
      ...data,
      CatalogueName: "SHIPMENT_MODE",
      Props: "{}",
    };
    const res = await updateCatalogueEntry(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteCatalogueEntry(id);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <LGCrud
      data={list}
      columns={[
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.7,
          editable: true,
        },
        {
          field: "code",
          headerName: "Clave",
          flex: 0.3,
          editable: true,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
      }}
    />
  );
}
