import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  MenuItem,
} from "@mui/material";
import { addProviderContact } from "../../../api/providers";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import { getCatalogue } from "../../../api/common/catalogues";

// Validation schema
const validationSchema = yup.object({
  name: yup.string("Ingrese el nombre").required("El nombre es obligatorio"),
  contactTypeId: yup.number().integer(),
  email: yup
    .string("Ingrese el correo electrónico")
    .email("Ingrese un correo electrónico válido")
    .required("El correo electrónico es obligatorio"),
  phone: yup
    .string("Ingrese el teléfono")
    .required("El teléfono es obligatorio"),
  mobile: yup
    .string("Ingrese el teléfono móvil")
    .required("El teléfono móvil es obligatorio"),
  ext: yup.string("Ingrese la extensión"),
  acceptsCreceipts: yup.boolean(),
});

export default function AddContactForm({ props }) {
  const { openSnack } = useSnack();
  const { providerId, handleAddContact } = props;
  const [contactTypes, setContactTypes] = useState([]);

  const formik = useFormik({
    initialValues: {
      providerId: "",
      name: "",
      contactTypeId: "",
      email: "",
      phone: "",
      mobile: "",
      ext: "",
      acceptsCreceipts: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = { ...values, providerId };
      try {
        const data = await addProviderContact(payload);
        openSnack("El contacto se guardó exitosamente", "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
      handleAddContact();
    },
  });

  const fetchData = async () => {
    try {
      const res = await getCatalogue("CONTACT_TYPE");
      setContactTypes(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ mt: 3 }}
      noValidate
      autoComplete="off"
    >
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Nombre del contacto"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        margin="normal"
      />
      <TextField
        fullWidth
        select
        id="contactTypeId"
        name="contactTypeId"
        label="Tipo de Contacto"
        value={formik.values.contactTypeId}
        onChange={formik.handleChange}
        error={
          formik.touched.contactTypeId && Boolean(formik.errors.contactTypeId)
        }
        helperText={formik.touched.contactTypeId && formik.errors.contactTypeId}
        margin="normal"
      >
        {contactTypes.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Correo Electrónico"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        margin="normal"
      />
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="Teléfono"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        margin="normal"
      />
      <TextField
        fullWidth
        id="mobile"
        name="mobile"
        label="Teléfono Móvil"
        value={formik.values.mobile}
        onChange={formik.handleChange}
        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
        helperText={formik.touched.mobile && formik.errors.mobile}
        margin="normal"
      />
      <TextField
        fullWidth
        id="ext"
        name="ext"
        label="Extensión"
        value={formik.values.ext}
        onChange={formik.handleChange}
        error={formik.touched.ext && Boolean(formik.errors.ext)}
        helperText={formik.touched.ext && formik.errors.ext}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            id="acceptsCreceipts"
            name="acceptsCreceipts"
            checked={formik.values.acceptsCreceipts}
            onChange={formik.handleChange}
          />
        }
        label="¿Acepta contrarecibo?"
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: 2 }}
      >
        Guardar
      </Button>
    </Box>
  );
}
