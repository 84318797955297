import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import LGModalCrud from "../../organisms/LGCrud/LGModalCrud";
import CrudProducts from "../../organisms/Products";

export default function ProductScreen() {
  return (
    <DashboardLayout>
      <CrudProducts props={{ companyId: 1 }} />
    </DashboardLayout>
  );
}
